import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/firebase-analytics";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBVPnxv92jfanKyVm39sWwg6DArUbozGV4",
  authDomain: "explified-app.firebaseapp.com",
  databaseURL: "https://explified-app.firebaseio.com",
  projectId: "explified-app",
  storageBucket: "explified-app.appspot.com",
  messagingSenderId: "901696391731",
  appId: "1:901696391731:web:cc7fdab6bb43b23388b146",
  measurementId: "G-MKQECZX6Q7",
};
class FireBase {
  constructor() {
    this.app = firebase.initializeApp(firebaseConfig);
    this.analytics = this.app.analytics(); //firebase.analytics();
    this.auth = firebase.auth();
    this.user = firebase.auth().currentUser;

    this.db = this.app.firestore();
    this.storage = firebase.storage();

    this.usersRef = this.db.collection("users");
    this.affiliateFormRef = this.db.collection("Affiliate Page");
    this.GoogleProviderID = new firebase.auth.GoogleAuthProvider().providerId;
    this.PhoneProviderID = new firebase.auth.PhoneAuthProvider().providerId;
  }

  isLoggedIn = () => {
    return this.user ? true : false;
  };
  // componentDidMount = () => {
  //   console.log("lofffff", this.user);
  //   this.auth.onAuthStateChanged(async (user) => {
  //     if (user) {
  //       this.setState = {
  //         isLoggedIn: true,
  //       };
  //       console.log("lofffff", user);
  //     } else {
  //       this.setState = {
  //         isLoggedIn: false,
  //       };
  //       console.log("hjhjhjh");
  //     }
  //   });
  // };

  // get token
  getToken = () => {
    return new Promise((resolve, reject) => {
      if (this.auth.currentUser) {
        this.auth.currentUser.getIdToken(true).then((token) => {
          resolve(token);
        });
      } else {
        resolve(null);
      }
    });
  };

  getUser = () => {
    this.auth.onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
      }
    });
    return this.user;
  };

  createUser = async (email, password) => {
    try {
      const res = await this.auth.createUserWithEmailAndPassword(
        email,
        password
      );
      this.user = res.user;
      return { success: true, error: null };
      // console.log("creare user success", res);
    } catch (error) {
      var errorMessage = error.message;
      return { success: false, error: errorMessage };
      // // Handle Errors here.
      // var errorCode = error.code;
      // var errorMessage = error.message;
      // console.log("create user error", error);
    }
  };

  captchaGeneraterforPhone = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "sign-up-button",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // this.onSignInSubmit();
        },
      }
    );
  };

  onSignInSubmit = (phone) => {
    return new Promise((res, rej) => {
      const phoneNumber = phone;
      const appVerifier = window.recaptchaVerifier;

      firebase
        .auth()
        .signInWithPhoneNumber(phoneNumber, appVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;

          res({ success: true, error: null });
        })
        .catch((error) => {
          // Error; SMS not sent
          // window.recaptchaVerifier.render().then((widgetId) => {
          //   window.recaptchaWidgetId = widgetId;
          // });
          console.log(error);
          rej({ success: false, error: error.message });
        });
    });
  };
  verifyOTP = (data, password, code) => {
    return new Promise((resolve, reject) => {
      window.confirmationResult
        .confirm(code)
        .then((result) => {
          // User signed in successfully.
          this.user = result.user;

          // updating display name
          this.auth.currentUser.updateProfile({
            displayName: data.fName + " " + data.lName,
          });

          // getting user credential
          var cred = firebase.auth.EmailAuthProvider.credential(
            data.email,
            password
          );
          // linking phone and email
          this.auth.currentUser
            .linkWithCredential(cred)
            .then((result) => {
              resolve({ success: true, error: null });
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          // User couldn't sign in (bad verification code?)
          console.log(error);
          reject({
            success: false,
            error:
              error.code === "auth/invalid-verification-code"
                ? "Please enter valid OTP"
                : error.message,
          });
        });
    });
  };

  updateUserEmail = async (email, id) => {
    console.log(email);
    firebase.auth().currentUser.updateEmail(email).then(()=>{this.updateProfileDetails(id, {email:email})}).catch((error)=>{console.log(error)});
  }

  updateUserPhone = async (phone, id) => {
    console.log(phone);
    const snapshot = await firebase.auth()
      .verifyPhoneNumber(phone)
      .on('state_changed', phoneAuthSnapshot => {
        console.log('Snapshot state: ', phoneAuthSnapshot.state);
      });
    const credential = firebase.auth.PhoneAuthProvider.credential(
      snapshot.verificationId,
      snapshot.code,
    );
    console.log('credential', credential);
    firebase.auth().currentUser.updatePhoneNumber(credential).then(()=>{this.updateProfileDetails(id, {phone:phone})}).catch((error)=>{console.log(error)});

  };


  signInUser = async (email, password) => {
    try {
      const res = await this.auth.signInWithEmailAndPassword(email, password);
      // console.log("sign in  user", res);
      this.user = res.user;

      return { success: true, error: null };
    } catch (error) {
      // Handle Errors here.
      // var errorCode = error.code;
      var errorMessage = error.message;
      // console.log("sign in user error", error);
      return { success: false, error: errorMessage };
    }
  };

  signOutUser = async () => {
    try {
      this.user = null;
      await this.auth.signOut();
      // console.log("Sign out successful");
    } catch (error) {
      // console.log("error signing out", error);
    }
  };


  getForms = (status,id) => {
    return this.affiliateFormRef.where('id','==',id).where('status','==',status).orderBy("timestamp", "desc").get();
}

  addAffiliateForm = async (record) => {
    return this.affiliateFormRef.add({ ...record });
  };

  updateAffiliateForm = async (id, record) => {
    return this.affiliateFormRef.doc(id).update(record);
  };

  deleteAffiliateForm = async (id) => {
    return this.affiliateFormRef.doc(id).delete();
  };

  fromSecondsToTimestamp = (seconds, nanoseconds = 0) => {
    if (!seconds) return firebase.firestore.Timestamp.now();
    const newTimestamp = new firebase.firestore.Timestamp(seconds, nanoseconds);
    return newTimestamp;
  };

  resetPassword = async (email) => {
    return this.auth.sendPasswordResetEmail(email);
  };

  addUserRecord = (record) => {
    return this.usersRef
      .doc(this.user.uid)
      .set({ ...record, UserID: this.user.uid, Url: "" });
  };
  getUserProfileDetails = (id) => {
    return this.usersRef.doc(id).get();
  };
  getUserProfileImage = () => {
    return this.storage;
  };
  updateProfileDetails = (id, details) => {
    return this.usersRef.doc(id).update(details);
  };
  sendEmailVerification = () => {
    var user = this.getUser();
    try {
      if (!user.emailVerified) {
        user.sendEmailVerification();
        return { success: true, error: null };
      } else {
        // console.log("Already verified");
        return { verified: true };
      }
    } catch (error) {
      return { success: false, error: error.message };
    }
  };
}
export default FireBase;
