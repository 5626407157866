import {React, useEffect} from 'react';
import { Grid, TextField } from '@material-ui/core';
import ButtonComponent from './ButtonComponent';
import { Typography } from '@material-ui/core';
import { IconButton, Button } from '@material-ui/core';
import { Person } from '@material-ui/icons';
import { Menu } from '@material-ui/icons';
import { withFirebase } from '../firebase';
import { withRouter } from 'react-router';
import Navbar from './Navbar';
import './LandingPage.css';
import OverviewDialog from './OverviewDialog';

const CustomerMain = ({firebase, history}) => {

    useEffect(() => {
        firebase.auth.onAuthStateChanged(async (userAuth) => {
          if (!userAuth) {
            history.push("/");
          }
        });
      }, []);

    return(
        
        <Grid className='Page'>
            <Navbar firebase={firebase}/>
            <Grid className='form-container'>
                <div className='page-container'>
                    <div className='column'><ButtonComponent/></div>
                    <div className='button-container' style={{marginTop:30}}>
                        <Typography style={{fontSize:80, color:'darkturquoise', margin:20, fontWeight:'bold'}}>OVERVIEW</Typography>
                        <div style={{display:'inline-flex'}}>
                            <Typography style={{fontSize:45, color:'darkturquoise', marginLeft:40, fontWeight:'bold'}}>Earnings</Typography>
                            <Typography style={{fontSize:45, color:'darkturquoise', marginLeft:140, fontWeight:'bold'}}>Sales</Typography>
                            <Typography style={{fontSize:45, color:'darkturquoise', marginLeft:140, fontWeight:'bold'}}>Clicks  </Typography>
                            <div style={{marginLeft:150}}>
                                <OverviewDialog/>
                            </div>
                        </div>
                        <div style={{display:'inline-flex', float:'left'}}>
                            <div style={{backgroundColor:'darkturquoise', height:60, marginLeft:30, width:230, textAlign:'center'}}>Rs. 1234</div>
                            <div style={{backgroundColor:'darkturquoise', marginLeft:60, height:60, width:230, textAlign:'center'}}>Rs. 1234</div>
                            <div style={{backgroundColor:'darkturquoise', marginLeft:60, height:60, width:190, textAlign:'center'}}>Rs. 1234</div>
                        </div>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
}
const Component = withFirebase(CustomerMain);

export default withRouter(Component);