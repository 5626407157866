import React from 'react';
import { Grid } from '@material-ui/core';
import ButtonComponent from './ButtonComponent';
import { Typography } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { Person } from '@material-ui/icons';
import { withFirebase } from '../firebase';
import { withRouter } from 'react-router';
import Navbar from './Navbar';
import './LandingPage.css'

const FAQS = ({firebase, history}) => {
    return(
        <Grid className='Page'>
            <Navbar firebase={firebase}/>
            <Grid className='form-container'>
                <div className='page-container'>
                    <div className='column'><ButtonComponent/></div>
                    <div className='button-container' style={{marginTop:30}}>
                        <Typography style={{fontSize:50, color:'darkturquoise', margin:20, fontWeight:'bold'}}>FREQUENTLY ASKED QUESTIONS</Typography>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
}
const Component = withFirebase(FAQS);

export default withRouter(Component);