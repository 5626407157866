import {React, useEffect, useRef, useState} from 'react';
import { Grid, TextField } from '@material-ui/core';
import ButtonComponent from './ButtonComponent';
import { Typography } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { Person } from '@material-ui/icons';
import { withFirebase } from '../firebase';
import { Button, FormControl } from '@material-ui/core';
import { withRouter } from 'react-router';
import Navbar from './Navbar';
//import VerifyOTP from './VerifyOTP';

const Manage = ({firebase, history}) => {

    const [user, setUser] = useState("");
    const userId = useRef("");
    const [newEmail, setNewEmail] = useState("");
    const [newPhone, setNewPhone] = useState("");
    const [verify, showVerify] = useState(false);

    useEffect(() => {
        firebase.auth.onAuthStateChanged(async (userAuth) => {
          if (userAuth) {
            userId.current = userAuth.uid;
            let userDetails = await firebase.getUserProfileDetails(userAuth.uid)
            const data = userDetails.data();
            console.log(data);
            if(data){
              setUser({...userAuth, name:data.fName+" "+data.lName, email:data.email, phone:data.phone});}
              console.log(user.email)
            }
          else{
            history.push("/");
        }
        });
      }, []);

    const handlePhoneSubmit = () => {
        showVerify(true);
        firebase.captchaGeneraterforPhone();
        firebase.onSignInSubmit(newPhone);
    }

      const handleEmailSubmit = () => {
          const res = firebase.updateUserEmail(newEmail, user.uid)
          if(res){
          setNewEmail("");
        }
      }

    return(
        <>
        {/* <VerifyOTP verify={verify} showVerify={showVerify}/> */}
        <Grid className='Page'>
            <Navbar firebase={firebase}/>
            <Grid className='form-container'>
                <div className='page-container'>
                    <div className='column'><ButtonComponent/></div>
                    <div className='button-container' style={{marginTop:30}}>
                        <Typography style={{fontSize:60, color:'darkturquoise', margin:20, fontWeight:'bold'}}>MANAGE YOUR ACCOUNT</Typography>
                        <Typography style={{margin:20, fontSize:40}}>Change E-mail</Typography>
                        <div style={{display:'inline-flex', marginLeft:20, width:'100%'}}>
                            <Typography style={{marginRight:20,marginTop:10, fontSize:20}}>Old Email : </Typography>
                            <TextField
                                type="text"
                                disabled
                                variant='outlined'
                                value={user.email}
                            />
                            <Typography style={{marginLeft:60, marginRight:20,marginTop:10, fontSize:20}}>New Email : </Typography>
                            <FormControl>
                            <TextField
                                type="text"
                                variant='outlined'
                                value={newEmail}
                                onChange={(e) => {setNewEmail(e.target.value)}}
                            />
                            </FormControl>
                        </div>
                        <Button style={{margin:20}} variant='contained' color='primary' onClick={handleEmailSubmit}>Change Email</Button>
                        <Typography style={{margin:20, fontSize:40}}>Change Contact Number</Typography>
                        <div style={{display:'inline-flex', marginLeft:20, marginTop:10, width:'100%'}}>
                            <Typography style={{marginRight:20,marginTop:10, fontSize:20}}>Old Contact : </Typography>
                            <TextField
                                type="text"
                                disabled
                                variant='outlined'
                                value={user.phone}
                            />
                            <Typography style={{marginLeft:60, marginRight:20,marginTop:10, fontSize:20}}>New Contact : </Typography>
                            <FormControl>
                            <TextField
                                type="text"
                                variant='outlined'
                                value={newPhone}
                                onChange={(e) => {setNewPhone(e.target.value)}}
                            />
                            </FormControl>
                        </div>
                        <Button style={{margin:20}} variant='contained' color='primary' >Change Contact</Button>
                    </div>
                </div>
            </Grid>
        </Grid>
        </>
    );
}
const Component = withFirebase(Manage);

export default withRouter(Component);