import React from 'react';
import { Button } from '@material-ui/core';
import {Link} from 'react-router-dom';

const ButtonComponent = () => {
    return(
        <div style={{marginTop:150, marginLeft:5}}>
            <Link to="/dashboard" style={{textDecoration:'none'}}>
                <Button style={{borderRadius:40, margin:10, width:180, height:60}} variant='contained'>DASHBOARD</Button>
            </Link>
            <Link to="/analytics" style={{textDecoration:'none'}}>
                <Button style={{borderRadius:40, margin:10, width:180, height:60}} variant='contained'>ANALYTICS</Button>
            </Link>
            <Link to="/faqs" style={{textDecoration:'none'}}>
                <Button style={{borderRadius:40, margin:10, width:180, height:60}} variant='contained'>FAQS</Button>
            </Link>
            <Link to="/support" style={{textDecoration:'none'}}>
                <Button style={{borderRadius:40, margin:10, width:180, height:60}} variant='contained'>CONTACT US</Button>
            </Link>
            <Link to="/manage" style={{textDecoration:'none'}}>
                <Button style={{borderRadius:40, margin:10, width:180, height:60}} variant='contained'>MANAGE</Button>
            </Link>
        </div>
    );
}

export default ButtonComponent; 