import {React, useState} from 'react';
import { TextField, Typography } from '@material-ui/core';
import { Container } from 'react-bootstrap';
import { Grid } from '@material-ui/core';
import {Link} from 'react-router-dom';
import { Button } from '@material-ui/core';

const PaymentDetails = () => {
    
    const [bankName, setBankName] = useState("");
    const [accNo, setAccNo] = useState("");
    const [ifsc, setIfsc] = useState("");
    const [beneficiary, setBeneficiary] = useState("");
    const [branch, setBranch] = useState("");

    return(
        <Container style={{margin:20, width:800}}>
            <Typography style={{fontSize:80, color:'darkturquoise', marginTop:20, fontWeight:'bold'}}>Payment Details: </Typography>
            <Grid>
                <div fullwidth> 
                    <TextField fullWidth
                      style={{marginTop:20}} 
                      placeholder='Name of the Bank'
                      variant='outlined'
                      type="text"
                      value={bankName}>
                      </TextField>
                </div>
                <div fullwidth>
                    <TextField fullWidth
                      style={{marginTop:20}} 
                      placeholder='Account Number'
                      variant='outlined'
                      type="text"
                      value={accNo}></TextField>
                </div>
                <div fullwidth>
                    <TextField 
                      style={{marginTop:20, width:'60%', float:'left'}} 
                      placeholder='IFSC Code'
                      variant='outlined'
                      type="text"
                      value={ifsc}></TextField>
                </div>
                <div fullwidth>
                    <TextField 
                      style={{marginTop:20, width:'60%', float:'left'}} 
                      placeholder='Name of Beneficiary'
                      variant='outlined'
                      type="text"
                      value={beneficiary}></TextField>
                </div>
                <div fullwidth>
                    <TextField fullWidth
                      style={{marginTop:20}} 
                      placeholder='Bank Branch'
                      variant='outlined'
                      type="text"
                      value={branch}></TextField>
                </div>
                <Link to="/dashboard" style={{textDecoration:'none'}}>
                <Button variant='contained' style={{marginTop:40, float:'left', width:160, height:80, color:'white', backgroundColor:'black'}}><Typography style={{fontSize:25}}>Submit</Typography></Button>
                </Link>
            </Grid>
        </Container>
    );
}

export default PaymentDetails;