import {React, useState, useEffect, useRef} from 'react';
import {
  Avatar,
  Grid,
  Paper,
  TextField,
  Button,
  Typography,
  FormControl,
  IconButton,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { pink } from "@material-ui/core/colors";
import { Container } from 'react-bootstrap';
import { withFirebase } from "../firebase";
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import PopUpToast from './PopUpToast';

const useStyles = makeStyles((theme) => ({
  margin: {
    marginRight: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  pink: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
  },
}));

const Details = ({ firebase, history }) => {
  const classes = useStyles();
  const [successSnackBarOpen, setSuccessSnackBarOpen] = useState(false);
  const [signingIn, setSigningIn] = useState(false);
  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [mob, setMob] = useState("");
  const [web, setWeb] = useState("");
  const [paypalId, setPaypalId] = useState("");
  const [webError, setWebError] = useState("");
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const userId = useRef("");

  console.log(user);

  useEffect(() => {
    firebase.auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        userId.current = userAuth.uid;
        let userDetails = await firebase.getUserProfileDetails(userAuth.uid)
        const data = userDetails.data();
        console.log(data);
        if(data){
          setUser({...userAuth, name:data.fName+" "+data.lName, email:data.email, phone:data.phone, url:data.Url});}
        }
      else{
        history.push("/");
    }
    });
  }, []);
  // useEffect(() => {
  //   if (!!firebase.user) {
  //     history.push("/");
  //   }
  // }, []);

  

  const handleFormSubmit = async () => {
    // if (name.length === 0) {
    //   setMobError("");
    //   setEmailError("");
    //   setWeb("");
    //   return setNameError("Please enter valid name");
    // }
    // if (!new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email)) {
    //   setMobError("");
    //   setWeb("");
    //   return setEmailError("Enter valid email");
    // }
    // if (mob.length < 10) {
    //   setMobError("Invalid Mobile Number");
    //   return;
    // }

    
    // const newRecord = {
    //   email,
    //   mob,
    //   web,
    //   paypalId,
    // };

    setIsFormSubmitting(true);

    const res = firebase.updateUserEmail(email, user.uid);
    const res2 = firebase.updateUserPhone(mob, user.uid);
    if(res && res2){
      clearState();
      setSuccessSnackBarOpen(true);
      console.log("Details Updated");
    }
    else{
      setSuccessSnackBarOpen(false);
    }
    setIsFormSubmitting(false);
  };
  const clearState = () => {
    setEmail("");
    setMob("");
  };

  return (
    <Container style={{margin:20, width:800}}>
    <Typography style={{fontSize:80, color:'darkturquoise', marginTop:20, fontWeight:'bold'}}>Personal Details: </Typography>
    <Grid className='form'>
        <FormControl fullWidth>
            <TextField
              style={{marginTop:20}}
              variant='outlined'
              type="text"
              disabled
              value={user.name + " (non-editable)"}
            />
        </FormControl>
        <FormControl  fullWidth>
        <TextField 
            style={{marginTop:20}}
            variant='outlined'
            type="text"
            placeholder={user.email}
            value={email}
            onChange={(e)=>{setEmail(e.target.value)}}
            />
        </FormControl>
        <FormControl>
        <div style={{marginTop:20, display:'inline-flex'}}>
            <Typography style={{fontSize:30, marginTop:5}}>Country Code</Typography>
            <TextField style={{width:80, marginLeft:11}}
              variant='outlined'
              type="text"
              ></TextField>
            <TextField style={{width:500, marginLeft:20}}
            variant='outlined'
            type="text"
            placeholder={user.phone}
            value={mob}
            onChange={(e) => {setMob(e.target.value)}}
            />
        </div>
        </FormControl>
        {/* <FormControl fullWidth>
          <div style={{display:'inline-flex'}}>
            <Button style={{width:150, height:50, marginLeft:20, marginTop:20}} variant='contained' color='primary'>Send OTP</Button>
            <TextField style={{width:500, marginTop:20, marginLeft:130}} 
              placeholder='OTP'
              variant='outlined'
              type="text"
              value={otp}
              onChange={(e) => {setOtp(e.target.value)}}></TextField>
          </div>
        </FormControl> */}
        <FormControl fullWidth>
            <TextField 
              fullWidth
              style={{marginTop:20}} 
              placeholder='Website/Social Media URL'
              variant='outlined'
              type="text"
              value={web}
              onChange={(e) => {setWeb(e.target.value)}}></TextField>
        </FormControl>
        <FormControl fullWidth>
            <TextField 
              fullWidth
              style={{marginTop:20}} 
              placeholder='Paypal ID'
              variant='outlined'
              type="text"
              value={paypalId}
              onChange={(e) => {setPaypalId(e.target.value)}}></TextField>
        </FormControl>
        
        <FormControl fullWidth>
        <div style={{display:'inline-flex'}}>
        <Button variant='contained' style={{marginTop:40, width:160, height:80, color:'white', backgroundColor:'black'}} onClick={()=>{handleFormSubmit()}}><Typography style={{fontSize:25}}>Submit</Typography></Button>
        <Link to="/dashboard" style={{textDecoration:'none'}}>
          <Button style={{marginTop:40,marginLeft:30, width:160, height:80, color:'white', backgroundColor:'black'}}><Typography style={{fontSize:25}}>Cancel</Typography></Button>
        </Link>
        </div>
        </FormControl>
    </Grid>
    <PopUpToast
          successSnackBarOpen={successSnackBarOpen}
          setSuccessSnackBarOpen={setSuccessSnackBarOpen}
          vertical="top"
          horizontal="center"
          severity="success"
          message="Form Submitted Succesfully"
        />
</Container>
  );
};
const Component = withFirebase(Details);

export default withRouter(Component);
