import Chart from "react-google-charts";
import { CircularProgress } from "@material-ui/core";

const BarChart = () => {
  return (
    <div style={{ display: "flex", flexGrow: "1", flexWrap: "wrap" }}>
        <Chart
        width={600}
        height={200}
        chartType="ColumnChart"
        loader={<div>Loading Chart<CircularProgress/></div>}
        data={[
          ["City", "2010 Population", "2000 Population"],
          ["New York City, NY", 8175000, 8008000],
          ["Los Angeles, CA", 3792000, 3694000],
          ["Chicago, IL", 2695000, 2896000],
          ["Houston, TX", 2099000, 1953000],
          ["Philadelphia, PA", 1526000, 1517000],
        ]}
        options={{
          title: "Population of Largest U.S. Cities",
          chartArea: { width: "30%" },
          hAxis: {
            title: "Total Population",
            minValue: 0,
          },
          vAxis: {
            title: "City",
          },
          animation: {
            duration: 1000,
            easing: 'out',
            startup: true,
          },
        }}
        legendToggle
      />
    </div>
  );
};

export default BarChart;