import {React, useEffect, useState} from 'react';
import './LandingPage.css'
import { IconButton } from '@material-ui/core';
import { PersonOutline } from '@material-ui/icons';
import { Container, Row , Col } from 'react-bootstrap';
import { Grid } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { withFirebase } from '../firebase';
import {Link, withRouter} from 'react-router-dom';

const LandingPage = ({firebase, history}) => {

  const [user, setUser] = useState("");

  useEffect(() => {
    firebase.auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        history.push("/dashboard");
      }
    });
  }, []);

  return ( 
    <Grid className='Page'>
      <Grid className='form-container'>
        <div className='page-container'>
        <div className='column'></div>
        <div className='button-container'>
          <div style={{textAlign:'center'}}>
            <Typography style={{fontSize:60, color:'darkturquoise', marginTop:20}}>Make Money Using</Typography>
            <Typography style={{color:'darkturquoise', fontStyle:'italic',fontWeight:'bold', fontSize:70, }}>Explified Affiliate Program</Typography>
          </div>
              <IconButton className='round-button' style={{margin:20, backgroundColor:'powderblue'}}><Container><Row><PersonOutline style={{height:'200px', width:'200px'}}/></Row><Row>JOIN</Row></Container></IconButton>
              <IconButton className='round-button' style={{margin:20, backgroundColor:'powderblue'}}><Container><Row><img src="https://cdn.iconscout.com/icon/free/png-512/link-3114411-2598189.png" style={{height:'200px', width:'200px'}}/></Row><Row>LINK</Row></Container></IconButton>
              <IconButton className='round-button' style={{margin:20, backgroundColor:'powderblue'}}><Container><Row><img src="https://static.thenounproject.com/png/2370802-200.png" style={{height:'200px', width:'200px'}}/></Row><Row>EARN</Row></Container></IconButton>
              <div style={{float:'right'}}>
                <Link to="/login" style={{textDecoration:'none'}}>
                <Button variant='contained' style={{margin:20, borderRadius:30, width:160, height:80}}>Sign In</Button>
                </Link>
                <Link to="/sign-up" style={{textDecoration:'none'}}>
                <Button variant='contained' style={{margin:20, borderRadius:30, width:160, height:80}}>Sign Up</Button>
                </Link>
              </div>
        </div>
        </div>
      </Grid>
    </Grid>
  );
}
const Component = withFirebase(LandingPage);

export default withRouter(Component);
