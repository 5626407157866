import { BrowserRouter } from 'react-router-dom';
import { Switch } from 'react-router';
import { Route } from 'react-router';
import LandingPage from './components/LandingPage';
import Details from './components/Details';
import PaymentDetails from './components/PaymentDetails';
import CustomerMain from './components/CustomerMain';
import Support from './components/Support';
import Manage from './components/manage';
import UserSignUp from './components/UserSignUp';
import UserSignIn from './components/UserSignIn/UserSignIn';
import FAQS from './components/FAQS';
import Analytics from './components/Analytics';

const Main = () => {
  
  return (
    <BrowserRouter>
      <Switch>
        <Route 
            path = "/details" 
            component = {(props) => (<Details {...props}/>)}
        />
        
        <Route 
            path = "/payment-details" 
            component = {(props) => (<PaymentDetails {...props}/>)}
        /> 
        
        <Route 
            path = "/dashboard" 
            component = {(props) => (<CustomerMain {...props}/>)}
        />
        
        <Route 
            path = "/support" 
            component = {(props) => (<Support {...props}/>)}
        />
        
        <Route 
            path = "/manage" 
            component = {(props) => (<Manage {...props}/>)}
        />
        
        <Route 
            path = "/faqs" 
            component = {(props) => (<FAQS {...props}/>)}
        /> 

        <Route 
          path="/login" 
          component={(props) => <UserSignIn {...props} />} 
        />

        <Route
          path="/sign-up"
          component={(props) => <UserSignUp {...props} />}
        />

        <Route
          path="/analytics"
          component={(props) => <Analytics {...props}/>}
        />
        
        <Route 
            path="/" 
            component = {(props) => (<LandingPage {...props}/>)}
        />
      </Switch>
    </BrowserRouter>
  );
}

export default Main;