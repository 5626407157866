import React from 'react';
import Button from '@material-ui/core/Button';
import {Menu as List} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/icons/Menu';

export default function MenuDialog() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button style={{float:'right'}} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <Menu style={{color:'darkturquoise', width:40, height:40}}/>
      </Button>
      <List
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>This Week</MenuItem>
        <MenuItem onClick={handleClose}>This Month</MenuItem>
      </List>
    </div>
  );
}
