import {React, useState} from 'react';
import { Grid } from '@material-ui/core';
import ButtonComponent from './ButtonComponent';
import { Typography } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { Person, Filter } from '@material-ui/icons';
import { withFirebase } from '../firebase';
import Navbar from './Navbar';
import { withRouter } from 'react-router';
import { Container, Col, Row } from 'react-bootstrap';
import LineChart from './LineChart';
import { Button } from '@material-ui/core';
import PieChart from './PieChart';
import BarChart from './BarChart';
import Menu from '@material-ui/icons/Menu';
import { Menu as List } from '@material-ui/core';
import MenuDialog from './MenuDialog';
import { MenuItem } from '@material-ui/core';
import FilterDialog from './FilterDialog';

const Analytics = ({firebase, history}) => {

    return(
        <Grid className='Page'>
            <Navbar firebase={firebase}/>
            <Grid className='form-container'>
                <div className='page-container'>
                    <div className='column'><ButtonComponent/></div>
                    <div className='button-container' style={{marginTop:30}}>
                        <Container>
                        <Row>
                        <div style={{display:'inline-flex', width:'100%'}}>
                        <Col style={{width:'50%'}}>
                        <Typography style={{fontSize:60, color:'darkturquoise', marginLeft:20, marginTop:20, fontWeight:'bold'}}>
                            Analytics
                        </Typography>
                        <Typography style={{fontSize:40, color:'darkturquoise', marginLeft:20, fontWeight:'bold'}}>
                            Traffic
                            <div style={{float:'right'}}>
                                <MenuDialog/>
                            </div>
                            <div style={{float:'right'}}>
                                <FilterDialog/>
                            </div>
                        </Typography>
                        <div>
                            <LineChart/>
                        </div>
                        <Typography style={{fontSize:40, color:'darkturquoise', marginLeft:20, fontWeight:'bold'}}>
                            Compare
                            <div style={{float:'right'}}>
                                <MenuDialog/>
                            </div>
                            <div style={{float:'right'}}>
                                <FilterDialog/>
                            </div>
                        </Typography>
                        <div style={{display:'inline-flex'}}>
                            <BarChart/>    
                        </div>
                        </Col>
                        <Col style={{width:'45%'}}>
                            <Typography style={{fontSize:40, color:'darkturquoise', marginLeft:20, marginTop:280, fontWeight:'bold'}}>
                                Sources
                                <div style={{float:'right'}}>
                                    <MenuDialog/>
                                </div>
                                <div style={{float:'right'}}>
                                <FilterDialog/>
                                </div>
                            </Typography>
                            <PieChart/>
                        </Col>
                        </div>
                        </Row>
                        </Container>
                        </div>
                </div>
            </Grid>
        </Grid>
    );
}
const Component = withFirebase(Analytics);

export default withRouter(Component);