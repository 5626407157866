import React from 'react';
import { Grid } from '@material-ui/core';
import ButtonComponent from './ButtonComponent';
import { Typography } from '@material-ui/core';
import { withFirebase } from '../firebase';
import { withRouter } from 'react-router';
import Navbar from './Navbar';

const Support = ({firebase, history}) => {
    return(
        <Grid className='Page'>
            <Navbar firebase={firebase}/>
            <Grid className='form-container'>
                <div className='page-container'>
                    <div className='column'><ButtonComponent/></div>
                    <div className='button-container' style={{marginTop:30}}>
                        <Typography style={{fontSize:60, color:'darkturquoise', margin:20, fontWeight:'bold'}}>SUPPORT</Typography>
                        <Typography style={{fontSize:40, margin:20, fontWeight:'bold'}}>email us at:</Typography>
                        <div style={{backgroundColor:'darkturquoise', margin:20, height:100}}>
                            <Typography style={{fontSize:25, fontWeight:'bold'}}>support@explified.com</Typography>
                        </div>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
}
const Component = withFirebase(Support);

export default withRouter(Component);