import React from 'react';
import Button from '@material-ui/core/Button';
import {Menu as List} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/icons/Menu';
import { IconButton } from '@material-ui/core';

export default function FilterDialog() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton style={{float:'right'}} onClick={handleClick}>
        <img style={{width:35, height:35}} src="https://cdn.iconscout.com/icon/free/png-512/filter-3114426-2598204.png"/>
    </IconButton>
      <List
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>Sales</MenuItem>
        <MenuItem onClick={handleClose}>Clicks</MenuItem>
      </List>
    </div>
  );
}
