import Chart from "react-google-charts";
import { CircularProgress } from "@material-ui/core";
const PieChart = () => {
  return (
    <div style={{ display: "flex", flexGrow: "1", flexWrap: "wrap" }}>
    <Chart
    width={"550px"}
    height={"320px"}
    chartType="PieChart"
    loader={<div>Loading Chart<CircularProgress/></div>}
    data={[
      ["Task", "Hours per Day"],
      ["Work", 11],
      ["Eat", 2],
      ["Commute", 2],
      ["Watch TV", 2],
      ["Sleep", 7],
    ]}
    options={{
      title: "My Daily Activities",
      // Just add this option
      is3D: true,
      
    }}
    rootProps={{ "data-testid": "2" }}
  />
  </div>
  );
};

export default PieChart;